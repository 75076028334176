<template>
    <inner-page-card heading="Permissions" class="mt-3 card m-0 p-0">

        <div v-if="loading" class="fl-eq">
            <loading-animation/>
        </div>

        <div v-else class="">
            <div class="row">
                <div class="col-12">
                    <div class="fl-te-c pl-lg-4">
                        <p class="font-inter-medium fs-3">Name : {{ userDetails.name }}</p>

                        <div>
                            <validated-checkbox :label="selectAll ? 'Deselect All' : 'Select All'"
                                                class="capitalize-string font-inter-medium fs-1"
                                                v-model="selectAll" :disabled="loading"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="permissionLoading" class="text-center py-5">
                <loading-animation/>
                <br/>
                <div>Permission loading...</div>
            </div>
            <div v-else>
                <b-form :key="selectAll" class="col-12 col-12" ref="form" :save-url="addUrl"
                        :save-params="{user: $route.params.id}"
                        @success="formSuccess" v-slot="{model, loading}" :initial-data="userRoles">

                    <div class="card">
                        <div class="row">
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_add_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_add_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_edit_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_edit_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_delete_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_delete_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>

                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_view_bidder')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_view_bidder"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
<!--                            <div class="col-12 col-lg-3">-->
<!--                                <validated-checkbox :label="setTitle('can_delete_bidder')"-->
<!--                                                    class="capitalize-string font-inter-medium fs-1"-->
<!--                                                    v-model="model.can_delete_bidder"-->
<!--                                                    :disabled="loading"></validated-checkbox>-->
<!--                            </div>-->
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_publish_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_approve_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_suspend_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_reject_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>

                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_approve_emd')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_approve_emd"
                                                    :disabled="loading"></validated-checkbox>
                            </div>

                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_add_participant')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_add_participant"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_reject_emd')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_reject_emd"
                                                    :disabled="loading"></validated-checkbox>
                            </div>

                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_upload_refund') + ' Template'"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_upload_refund"
                                                    :disabled="loading"></validated-checkbox>
                            </div>

                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_view_live_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_view_live_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_view_report')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_view_report"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                        </div>

                        <div class="row" v-if="userDetails.is_bank_admin">
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_add_bank_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_add_bank_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_edit_bank_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_edit_bank_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_delete_bank_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_delete_bank_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_accept_bank_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_accept_bank_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_reject_bank_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_reject_bank_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_cancel_live_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_cancel_live_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_upload_emd')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_upload_emd"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                        </div>

                        <div class="row" v-if="userDetails.is_ventura_admin">
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_add_ventura_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_add_ventura_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_edit_ventura_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_edit_ventura_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_delete_ventura_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_delete_ventura_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_approve_ventura_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_approve_ventura_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_reject_ventura_user')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_reject_ventura_user"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_add_bank')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_add_bank"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_edit_bank')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_edit_bank"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_delete_bank')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_delete_bank"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_aprove_bank')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_aprove_bank"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_reject_bank')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_reject_bank"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_add_bank_admin')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_add_bank_admin"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_edit_bank_admin')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_edit_bank_admin"
                                                    :disabled="loading"></validated-checkbox>
                            </div>

                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_delete_bank_admin')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_delete_bank_admin"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_approve_bank_admin')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_approve_bank_admin"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_reject_bank_admin')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_reject_bank_admin"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_manage_site')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_manage_site"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_upload_emd')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_upload_emd"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                            <div class="col-12 col-lg-3">
                                <validated-checkbox :label="setTitle('can_cancel_live_auction')"
                                                    class="capitalize-string font-inter-medium fs-1"
                                                    v-model="model.can_cancel_live_auction"
                                                    :disabled="loading"></validated-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="fl-x fl-x-tr mt-3">

                        <div class="fl-x btn-group">
                            <btn text="  Save  " :disabled="loading" :loading="loading"
                                 loading-text="Saving..." design="basic-a" class="px-4"></btn>
                        </div>

                    </div>

                </b-form>
            </div>
        </div>

        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="SuccessModal" width="25r"
               header-color="">

            <div class="text-center">
                <div class="mb-4">
                    <img src="../../assets/web/homee/icons/success-tick.png" class="icon-img-md" alt="">
                </div>

                <h4 class="mb-4 font-inter-bold">Permission Added</h4>

                <p class="font-inter-light mb-5">
                    Successfully updated the permission for the User <span v-html="userDetails.name"></span>.</p>

                <btn v-if="userDetails.is_bank_user" text="Done" class="" block
                     @click="$router.push({ path : '/users-list/' });"></btn>
                <btn v-else text="Done" class="" block @click="$router.push({ path : '/manage-users/0/' });"></btn>
            </div>

        </modal>
    </inner-page-card>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'AddUserPermission',

    data () {
        return {
            permissionLoading : false,
            loading           : true,
            error             : false,
            selectAll         : false,
            userDetails       : null,
            userRoles         : null,

            addUrl : urls.userRoles.addEdit
        };
    },

    watch : {
        selectAll (value) {
            this.setPermission(value);
        }
    },

    mounted () {
        this.loadData();
    },

    methods : {
        setPermission (value) {
            this.permissionLoading = true;
            if (value === true) {
                this.userRoles.can_add_auction = true;
                this.userRoles.can_edit_auction = true;
                this.userRoles.can_delete_auction = true;
                this.userRoles.can_view_bidder = true;
                // this.userRoles.can_delete_bidder = true;
                this.userRoles.can_approve_auction = true;
                this.userRoles.can_reject_auction = true;
                this.userRoles.can_approve_emd = true;
                this.userRoles.can_add_participant = true;
                this.userRoles.can_reject_emd = true;
                this.userRoles.can_upload_refund = true;
                this.userRoles.can_view_live_auction = true;
                this.userRoles.can_view_report = true;

                if (this.userDetails.is_bank_admin === true) {
                    this.userRoles.can_add_bank_user = true;
                    this.userRoles.can_edit_bank_user = true;
                    this.userRoles.can_delete_bank_user = true;
                    this.userRoles.can_accept_bank_user = true;
                    this.userRoles.can_reject_bank_user = true;
                    this.userRoles.can_cancel_live_auction = true;
                    this.userRoles.can_upload_emd = true;
                }

                if (this.userDetails.is_ventura_admin === true) {
                    this.userRoles.can_add_ventura_user = true;
                    this.userRoles.can_edit_ventura_user = true;
                    this.userRoles.can_delete_ventura_user = true;
                    this.userRoles.can_approve_ventura_user = true;
                    this.userRoles.can_reject_ventura_user = true;
                    this.userRoles.can_add_bank = true;
                    this.userRoles.can_edit_bank = true;
                    this.userRoles.can_delete_bank = true;
                    this.userRoles.can_aprove_bank = true;
                    this.userRoles.can_reject_bank = true;
                    this.userRoles.can_add_bank_admin = true;
                    this.userRoles.can_edit_bank_admin = true;
                    this.userRoles.can_delete_bank_admin = true;
                    this.userRoles.can_approve_bank_admin = true;
                    this.userRoles.can_reject_bank_admin = true;
                    this.userRoles.can_manage_site = true;
                    this.userRoles.can_upload_emd = true;
                    this.userRoles.can_cancel_live_auction = true;
                }
            } else {
                this.userRoles.can_add_auction = false;
                this.userRoles.can_edit_auction = false;
                this.userRoles.can_delete_auction = false;
                this.userRoles.can_view_bidder = false;
                // this.userRoles.can_delete_bidder = false;
                this.userRoles.can_approve_auction = false;
                this.userRoles.can_reject_auction = false;
                this.userRoles.can_upload_emd = false;
                this.userRoles.can_approve_emd = false;
                this.userRoles.can_add_participant = false;
                this.userRoles.can_reject_emd = false;
                this.userRoles.can_upload_refund = false;
                this.userRoles.can_add_bank_user = false;
                this.userRoles.can_edit_bank_user = false;
                this.userRoles.can_delete_bank_user = false;
                this.userRoles.can_accept_bank_user = false;
                this.userRoles.can_reject_bank_user = false;
                this.userRoles.can_cancel_live_auction = false;
                this.userRoles.can_view_live_auction = false;
                this.userRoles.can_view_report = false;
                this.userRoles.can_add_ventura_user = false;
                this.userRoles.can_edit_ventura_user = false;
                this.userRoles.can_delete_ventura_user = false;
                this.userRoles.can_approve_ventura_user = false;
                this.userRoles.can_reject_ventura_user = false;
                this.userRoles.can_add_bank = false;
                this.userRoles.can_edit_bank = false;
                this.userRoles.can_delete_bank = false;
                this.userRoles.can_aprove_bank = false;
                this.userRoles.can_reject_bank = false;
                this.userRoles.can_add_bank_admin = false;
                this.userRoles.can_edit_bank_admin = false;
                this.userRoles.can_delete_bank_admin = false;
                this.userRoles.can_approve_bank_admin = false;
                this.userRoles.can_reject_bank_admin = false;
                this.userRoles.can_manage_site = false;
                this.userRoles.can_cancel_live_auction = false;
            }
            const that = this;
            setTimeout(function () {
                that.permissionLoading = false;
            }, 1000);
        },
        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            axios.form(urls.userRoles.roleDetails, { id : that.$route.params.id }).then(function (response) {
                const json = response.data;
                that.userDetails = { ...json.user };
                that.userRoles = { ...json.data };
                that.loading = false;
                that.setInitial();
            }).catch(function (exception) {
                that.loading = false;
                that.error = true;
                console.log('exception : ', exception);
            });
        },

        setTitle (title) {
            return title.replaceAll('_', ' ');
        },

        formSuccess () {
            this.$refs.SuccessModal.show();
        },

        async setInitial () {
            const generalPermission = this.userRoles.can_add_auction && this.userRoles.can_edit_auction && this.userRoles.can_delete_auction &&
                this.userRoles.can_view_bidder && this.userRoles.can_approve_auction &&
                this.userRoles.can_reject_auction && this.userRoles.can_approve_emd && this.userRoles.can_add_participant &&
                this.userRoles.can_reject_emd && this.userRoles.can_upload_refund && this.userRoles.can_view_live_auction &&
                this.userRoles.can_view_report;
            this.selectAll = generalPermission;

            if (this.userDetails.is_bank_admin === true) {
                const bankAdminPermission = this.userRoles.can_add_bank_user && this.userRoles.can_edit_bank_user && this.userRoles.can_delete_bank_user &&
                    this.userRoles.can_accept_bank_user && this.userRoles.can_upload_emd && this.userRoles.can_reject_bank_user && this.userRoles.can_cancel_live_auction;
                this.selectAll = generalPermission && bankAdminPermission;
            }

            if (this.userDetails.is_ventura_admin === true) {
                const venturaAdminPermission = this.userRoles.can_add_ventura_user && this.userRoles.can_edit_ventura_user && this.userRoles.can_delete_ventura_user &&
                    this.userRoles.can_approve_ventura_user && this.userRoles.can_reject_ventura_user && this.userRoles.can_add_bank &&
                    this.userRoles.can_edit_bank && this.userRoles.can_upload_emd && this.userRoles.can_delete_bank && this.userRoles.can_aprove_bank &&
                    this.userRoles.can_reject_bank && this.userRoles.can_add_bank_admin && this.userRoles.can_edit_bank_admin &&
                    this.userRoles.can_delete_bank_admin && this.userRoles.can_approve_bank_admin && this.userRoles.can_reject_bank_admin &&
                    this.userRoles.can_manage_site && this.userRoles.can_cancel_live_auction;
                this.selectAll = generalPermission && venturaAdminPermission;
            }
        }
    }
};
</script>

<style scoped>

</style>
